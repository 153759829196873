<template>
  <section>
    <form-wizard color="#377DFF" :title="null" :subtitle="null" shape="square">
      <tab-content title="Cerca">
        <div v-if="!search">
          <div class="container w-lg-50 py-5 d-flex flex-column">
            <div>
              <p class="h4">Ricerca un'auto in EuroTax</p>
            </div>

            <img
              class="d-block mx-auto my-2"
              src="@/assets/images/auto-targa.svg"
            />

            <b-form-group label="Ricerca auto per numero di targa">
              <b-form-input placeholder="Inserisci il numero di targa" />
            </b-form-group>

            <b-form-group label="Ricerca auto per chilometraggio">
              <b-form-input placeholder="Inserisci km auto" />
            </b-form-group>

            <b-button
              class="mt-2"
              block
              variant="primary"
              @click="search = true"
              >Cerca</b-button
            >
          </div>
        </div>

        <div v-else>
          <b-table :items="items" :fields="fields">
            <template #cell(tipo)="data">
              <b-badge pill variant="primary">{{ data.item.tipo }}</b-badge>
            </template>
          </b-table>
        </div>
      </tab-content>

      <tab-content title="Accessori">
        <p class="text-primary h4">Accessori di Serie</p>

        <b-table
          :items="series_accessories_items"
          :field="series_accessories_fields"
        ></b-table>

        <p class="text-primary h4">Accessori di Sicurezza</p>

        <b-table
          :items="series_accessories_items"
          :field="series_accessories_fields"
        ></b-table>
      </tab-content>

      <tab-content title="Dettagli">
        <b-row>
          <b-col md="6">
            <b-form-group label="Nazionalità">
              <v-select
                id="vehicle_nationality"
                v-model="vehicle_nationality"
                :selectable="(option) => !option.value.includes('select_value')"
                label="text"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Provenienza">
              <v-select
                id="vehicle_origin"
                v-model="vehicle_origin"
                :selectable="(option) => !option.value.includes('select_value')"
                label="text"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Data Ingresso">
              <v-select
                id="vehicle_entry_date"
                v-model="vehicle_entry_date"
                :selectable="(option) => !option.value.includes('select_value')"
                label="text"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Ditta">
              <v-select
                id="vehicle_business"
                v-model="vehicle_business"
                :selectable="(option) => !option.value.includes('select_value')"
                label="text"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Marca">
              <v-select
                id="vehicle_brand"
                v-model="vehicle_brand"
                :selectable="(option) => !option.value.includes('select_value')"
                label="text"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Modello">
              <v-select
                id="vehicle_model"
                v-model="vehicle_model"
                :selectable="(option) => !option.value.includes('select_value')"
                label="text"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Versione">
              <v-select
                id="vehicle_version"
                v-model="vehicle_version"
                :selectable="(option) => !option.value.includes('select_value')"
                label="text"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Tipo">
              <v-select
                id="vehicle_type"
                v-model="vehicle_type"
                :selectable="(option) => !option.value.includes('select_value')"
                label="text"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Status veicolo">
              <v-select
                id="vehicle_status"
                v-model="vehicle_status"
                :selectable="(option) => !option.value.includes('select_value')"
                label="text"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Carrozzeria">
              <v-select
                id="vehicle_body"
                v-model="vehicle_body"
                :selectable="(option) => !option.value.includes('select_value')"
                label="text"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Numero chiave">
              <b-form-input
                id="vehicle_key_number"
                v-model="vehicle_key_number"
                placeholder="Inserisci il numero di chiave"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Doppia chiave">
              <v-select
                id="vehicle_has_double_key"
                v-model="vehicle_has_double_key"
                :selectable="(option) => !option.value.includes('select_value')"
                label="text"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Doppia chiave">
              <b-form-input
                type="date"
                v-model="vehicle_receive_date"
                placeholder="gg/mm/aa"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Numero passaggi">
              <b-form-input
                type="number"
                id="vehicle_passagge_no"
                v-model="vehicle_passagge_no"
                placeholder="Specifica il numero dei passaggi"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Anno d'immatricolazione">
              <v-select
                id="vehicle_matriculation_year"
                v-model="vehicle_matriculation_year"
                :selectable="(option) => !option.value.includes('select_value')"
                label="text"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Mese d'immatricolazione">
              <v-select
                id="vehicle_matriculation_month"
                v-model="vehicle_matriculation_month"
                :selectable="(option) => !option.value.includes('select_value')"
                label="text"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Chilometraggio">
              <b-form-input
                type="number"
                id="vehicle_kilometers"
                v-model="vehicle_kilometers"
                placeholder="Specifica il numero dei passaggi"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Ubicazione">
              <v-select
                id="vehicle_location"
                v-model="vehicle_location"
                :selectable="(option) => !option.value.includes('select_value')"
                label="text"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Tipo veicolo">
              <v-select
                id="vehicle_type_2"
                v-model="vehicle_type_2"
                :selectable="(option) => !option.value.includes('select_value')"
                label="text"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Cilindrata">
              <b-form-input
                type="number"
                id="vehicle_displacement"
                v-model="vehicle_displacement"
                placeholder="Specifica la cilindrata"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Potenza KW">
              <b-form-input
                type="number"
                id="vehicle_kw_power"
                v-model="vehicle_kw_power"
                placeholder="Specifica la potenza in KW"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Cavalli">
              <b-form-input
                type="number"
                id="vehicle_horsepower"
                v-model="vehicle_horsepower"
                placeholder="Specifica la potenza dei cavalli"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Alimentazione">
              <v-select
                id="vehicle_fuel"
                v-model="vehicle_fuel"
                :selectable="(option) => !option.value.includes('select_value')"
                label="text"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Tipo di cambio">
              <v-select
                id="vehicle_shift"
                v-model="vehicle_shift"
                :selectable="(option) => !option.value.includes('select_value')"
                label="text"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Targa">
              <b-form-input
                id="vehicle_plate"
                v-model="vehicle_plate"
                placeholder="Specifica il numero di targa"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Telaio">
              <b-form-input
                id="vehicle_chassis"
                v-model="vehicle_chassis"
                placeholder="Specifica il numero di telaio"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </tab-content>

      <tab-content title="Colore">
        <p class="text-primary h4">Colori degli <b>esterni</b></p>

        <b-row>
          <b-col md="12" class="my-1">
            <b-form-radio-group>
              <b-row>
                <b-col md="2" class="mb-2" v-for="color in colors_ext" :key="color.value">
                  <b-form-radio class="custom" :value="color.value" :style="{'--bg-color': color.color, '--border-color': color.color}">
                    {{color.text}}
                  </b-form-radio>
                </b-col>
              </b-row>
            </b-form-radio-group>

            <div class="d-flex mt-3">
              <b-form-checkbox
                id="checkbox-1"
                switch
                name="checkbox-1"
                value="metallic"
              >
                Vernice Metallizata
              </b-form-checkbox>

              <b-form-checkbox
                class="ml-3"
                id="checkbox-2"
                switch
                name="checkbox-2"
                :value="true"
                v-model="extra_color"
              >
                Vernice Bi-Color
              </b-form-checkbox>
            </div>
          </b-col>
        </b-row>

        <div v-if="extra_color">
          <hr />

          <p class="text-primary h4">Colore extra degli <b>esterni</b></p>

          <b-row>
            <b-col md="12" class="my-2">
              <b-form-radio-group>
              <b-row>
                <b-col md="2" class="mb-2" v-for="color in colors_ext" :key="color.value">
                  <b-form-radio class="custom" :value="color.value" :style="{'--bg-color': color.color, '--border-color': color.color}">
                    {{color.text}}
                  </b-form-radio>
                </b-col>
              </b-row>
            </b-form-radio-group>
            </b-col>
          </b-row>
        </div>

        <hr />

        <p class="text-primary h4">Colori degli <b>interni</b></p>

        <b-row>
          <b-col md="12" class="my-2">
            <b-form-radio-group>
              <b-row>
                <b-col md="2" class="mb-2" v-for="color in colors_int" :key="color.value">
                  <b-form-radio class="custom" :value="color.value" :style="{'--bg-color': color.color, '--border-color': color.color}">
                    {{color.text}}
                  </b-form-radio>
                </b-col>
              </b-row>
            </b-form-radio-group>
          </b-col>
        </b-row>

        <hr />

        <p class="text-primary h4">Materiali degli <b>interni</b></p>

        <b-row>
          <b-col md="12" class="my-2">
            <b-form-radio-group class="custom" :options="materials" />
          </b-col>
        </b-row>
      </tab-content>

      <tab-content title="Fornitore">
        <b-row>
          <b-col md="12">
            <b-form-group label="Fornitore">
              <v-select
                id="supplier"
                v-model="supplier"
                :selectable="(option) => !option.value.includes('select_value')"
                label="text"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Numero documento">
              <b-form-input
                v-model="document_number"
                placeholder="Specifica il numero del documento"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Presa in carico">
              <b-form-input
                v-model="take_in_charge"
                placeholder="Specifica la presa in carico"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </tab-content>

      <tab-content title="Costi Ripristino">
        <p class="text-primary h4">Costi di <b>ripristino</b></p>
        <b-row>
          <b-col md="6">
            <b-form-group label="Carrozzeria">
              <b-form-input
                placeholder="Specifica l'eventuale prezzo di ripristino"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Meccanica">
              <b-form-input
                placeholder="Specifica l'eventuale prezzo di ripristino"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Elettronica">
              <b-form-input
                placeholder="Specifica l'eventuale prezzo di ripristino"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Pneumatici">
              <b-form-input
                placeholder="Specifica l'eventuale prezzo di ripristino"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Altro">
              <b-form-input
                placeholder="Specifica l'eventuale prezzo di ripristino"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <hr />

        <b-row>
          <b-col md="12">
            <p class="h4 text-primary"><b>Valutazione EuroTax</b></p>
            <p>dati aggiornati al: gg/mm/aaaa</p>
          </b-col>

          <b-col class="mt-2" md="12">
            <p class="h4 text-primary">
              <b>Alimentazione</b>
            </p>
            <p>Gpl</p>
          </b-col>

          <b-col class="text-right my-3" md="12">
            <p class="text-primary h4"><b>Quotazione EuroTax Blu</b></p>
            <p class="text-primary h4">00000.00</p>
          </b-col>
        </b-row>
      </tab-content>
      <tab-content title="Prezzi Vendita">
        <p class="text-primary h4">Costi di <b>vendita</b></p>
        <b-row>
          <b-col md="6">
            <b-form-group label="Costi di ripristino">
              <b-form-input placeholder="Prezzo di ripristino" />
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Costi dei servizi">
              <b-form-input placeholder="Prezzo dei servizi" />
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Riepilogo prezzo d'acquisto">
              <b-form-input placeholder="Riepilogo prezzo di ripristino" />
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Prezzo d'acquisto">
              <b-form-input placeholder="Prezzo acquisto auto" />
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Valore Eurotax">
              <b-form-input placeholder="Valore Eurotax" />
            </b-form-group>
          </b-col>
        </b-row>

        <hr />

        <p class="text-primary h4">Prezzi</p>

        <b-row>
          <b-col md="6">
            <b-form-group label="Prezzo di vendita al privato">
              <b-form-input
                placeholder="Specifica il prezzo di vendita per i privati"
              />
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Prezzo da mostrare sul sito e portali web">
              <b-form-input
                placeholder="Specifica il prezzo da mostrare sulle piattaforme"
              />
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Prezzo di vendita al rivenditore">
              <b-form-input
                placeholder="Specifica il prezzo per l'acquisto del venditore"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </tab-content>
    </form-wizard>
  </section>
</template>

<script>
import {
  BBadge,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormRadio,
  BFormRadioGroup,
  BTable,
} from "bootstrap-vue";
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  components: {
    BBadge,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormRadio,
    BFormRadioGroup,
    BTable,
    FormWizard,
    TabContent,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },

  data: () => ({
    extra_color: false,

     colors_ext: [
      { text: "Beige", value: "beige", color:"#EBCF98"},
      { text: "Blu/Azzurro", value: "blu/azzurro", color:"#679CFF"},
      { text: "Marrone", value: "marrone", color:"#983700"},
      { text: "Bronzo", value: "bronzo", color: "#FFA16C" },
      { text: "Giallo", value: "giallo", color: "#FFF500" },
      { text: "Grigio", value: "grigio", color: "#ABA8B4"},
      { text: "Verde", value: "verde", color: "#21B015"},
      { text: "Rosso", value: "rosso", color: "#E70000" },
      { text: "Nero", value: "nero", color: "#000000" },
      { text: "Argento", value: "argento", color:"#ABA8B4"},
      { text: "Lilla", value: "lilla", color:"#EC74FF"},
      { text: "Bianco", value: "bianco", color:"#FFFFFF"},
      { text: "Arancione", value: "arancione", color:"#FB5A00"},
      { text: "Oro", value:"oro", color:"#FFB74A"},
    ],

    colors_int: [
      { text: "Beige", value: "beige", color:"#EBCF98"},
      { text: "Blu/Azzurro", value: "blu/azzurro", color:"#679CFF"},
      { text: "Grigio", value: "grigio", color: "#ABA8B4"},
      { text: "Marrone", value: "marrone", color:"#983700"},
      { text: "Bianco", value: "bianco", color:"#FFFFFF"},
      { text: "Rosso", value: "rosso", color: "#E70000" },
    ],

    materials: [
      { text: "Alcantara", value: "alcantara" },
      { text: "Pelle parziale", value: "pelle parziale" },
      { text: "Pelle scamosciata", value: "pelle scamosciata" },
      { text: "Stoffa", value: "stoffa" },
      { text: "Altro", value: "altro" },
    ],

    search: false,

    series_accessories_fields: ["tipo", "codice", "gruppo", "accessorio"],

    series_accessories_items: [
      {
        tipo: "Di Serie",
        codice: "1234567",
        gruppo: "Altro",
        accessorio: "Illuminazione abitacolo - Illuminazione bagagliaio",
      },
      {
        tipo: "Di Serie",
        codice: "1234567",
        gruppo: "Altro",
        accessorio: "Illuminazione abitacolo - Illuminazione bagagliaio",
      },
      {
        tipo: "Di Serie",
        codice: "1234567",
        gruppo: "Altro",
        accessorio: "Illuminazione abitacolo - Illuminazione bagagliaio",
      },
      {
        tipo: "Di Serie",
        codice: "1234567",
        gruppo: "Altro",
        accessorio: "Illuminazione abitacolo - Illuminazione bagagliaio",
      },
    ],

    fields: [
      "Marca - Modello - Versione",
      "Porte",
      "Cod. Eurotax",
      "Immatricolazione da data - a data",
      "Produzione inizio - fine",
      "Tipo",
      "Prezzo Vendita",
    ],

    items: [
      {
        "Marca - Modello - Versione":
          "Dacia - Sandero 0.9 tve La Gazzetta dello Sport s&s 90CV",
        Porte: "5",
        "Cod. Eurotax": "12345",
        "Immatricolazione da data - a data": "08/18 10/2016",
        "Produzione inizio - fine": "2015/08/04 2017/06/02",
        Tipo: "Benzina",
        "Prezzo Vendita": "6.500€",
      },
      {
        "Marca - Modello - Versione":
          "Dacia - Sandero 0.9 tve La Gazzetta dello Sport s&s 90CV",
        Porte: "5",
        "Cod. Eurotax": "12345",
        "Immatricolazione da data - a data": "08/18 10/2016",
        "Produzione inizio - fine": "2015/08/04 2017/06/02",
        Tipo: "Benzina",
        "Prezzo Vendita": "6.500€",
      },
      {
        "Marca - Modello - Versione":
          "Dacia - Sandero 0.9 tve La Gazzetta dello Sport s&s 90CV",
        Porte: "5",
        "Cod. Eurotax": "12345",
        "Immatricolazione da data - a data": "08/18 10/2016",
        "Produzione inizio - fine": "2015/08/04 2017/06/02",
        Tipo: "Benzina",
        "Prezzo Vendita": "6.500€",
      },
      {
        "Marca - Modello - Versione":
          "Dacia - Sandero 0.9 tve La Gazzetta dello Sport s&s 90CV",
        Porte: "5",
        "Cod. Eurotax": "12345",
        "Immatricolazione da data - a data": "08/18 10/2016",
        "Produzione inizio - fine": "2015/08/04 2017/06/02",
        Tipo: "Benzina",
        "Prezzo Vendita": "6.500€",
      },
      {
        "Marca - Modello - Versione":
          "Dacia - Sandero 0.9 tve La Gazzetta dello Sport s&s 90CV",
        Porte: "5",
        "Cod. Eurotax": "12345",
        "Immatricolazione da data - a data": "08/18 10/2016",
        "Produzione inizio - fine": "2015/08/04 2017/06/02",
        Tipo: "Benzina",
        "Prezzo Vendita": "6.500€",
      },
      {
        "Marca - Modello - Versione":
          "Dacia - Sandero 0.9 tve La Gazzetta dello Sport s&s 90CV",
        Porte: "5",
        "Cod. Eurotax": "12345",
        "Immatricolazione da data - a data": "08/18 10/2016",
        "Produzione inizio - fine": "2015/08/04 2017/06/02",
        Tipo: "Benzina",
        "Prezzo Vendita": "6.500€",
      },
      {
        "Marca - Modello - Versione":
          "Dacia - Sandero 0.9 tve La Gazzetta dello Sport s&s 90CV",
        Porte: "5",
        "Cod. Eurotax": "12345",
        "Immatricolazione da data - a data": "08/18 10/2016",
        "Produzione inizio - fine": "2015/08/04 2017/06/02",
        Tipo: "Benzina",
        "Prezzo Vendita": "6.500€",
      },
    ],
  }),
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
